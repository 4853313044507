@use 'breakpoints';
@use 'texts';
@import 'mixins';

.familyCardContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	text-decoration-line: none;

	.familyTextLabel {
		margin: 0;
		text-align: center;

		@extend %text-body-m;
	}

	.familyImage {
		position: relative;
		border-radius: 50%;
		width: px2rem(144);
		height: px2rem(144);
		overflow: hidden;
		object-fit: cover;

		@media (min-width: breakpoints.$large) {
			width: px2rem(192);
			height: px2rem(192);
		}
	}
}
