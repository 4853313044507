@use 'breakpoints';
@use 'texts';
@use 'mixins';

.wrapper {
	min-height: 48px;
	padding: 12px 48px;

	@media screen and (min-width: breakpoints.$large) {
		padding: 10px 144px;
	}

	&.withInfo {
		padding: 24px 48px;

		@media screen and (min-width: breakpoints.$large) {
			padding: 10px 144px;
		}

		&.withArrows {
			min-height: 120px;

			@media screen and (min-width: breakpoints.$large) {
				min-height: 48px;
			}
		}
	}

	&.withLinks {
		padding-bottom: 4px;

		@media screen and (min-width: breakpoints.$large) {
			padding-bottom: 10px;
		}
	}
}

.titlesCountdownWrapper {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 8px;

	@media screen and (min-width: breakpoints.$large) {
		flex-flow: row wrap;
		padding: 4px 0;
		gap: 4px 24px;
	}
}

.countdown {
	display: flex;
	align-items: center;
	gap: 4px;
	width: fit-content;
}

.countdownNumbers {
	min-width: 15px;
	text-align: center;
}

.links {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin: 0;
	padding: 0;
	gap: 0 16px;
	list-style-type: none;
}

.link {
	line-height: initial;

	@media screen and (min-width: breakpoints.$large) {
		padding: 0;
	}
}

.ctaLink {
	display: flex;
	align-items: center;
	height: 48px;

	@media screen and (min-width: breakpoints.$large) {
		height: 26px;
	}
}

.ctaNoLink {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	height: 48px;
	text-transform: uppercase;

	@extend %text-uppercase-m;

	@media screen and (min-width: breakpoints.$large) {
		height: 26px;
	}

	&:hover {
		text-decoration: underline;
		text-decoration-thickness: px2rem(1px);
		text-underline-offset: 8px;
	}
}
