@use 'colors';

.activeCta {
	background-color: colors.$background-accent-on-inverse-active;
}

.buttonTextContainer {
	display: flex;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
