@use 'colors';
@use 'texts';

.status {
	display: flex;

	.iconContainer {
		display: flex;
		align-items: center;

		.iconGreen {
			fill: colors.$content-sustainability;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	padding-left: 8px;
}

.stateText {
	@extend %text-title-m;
}
