@use 'breakpoints';

.scrollContainer {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	padding: 48px 16px;

	@media (min-width: breakpoints.$large) {
		padding: 48px 32px;
		width: 100%;
	}
}
