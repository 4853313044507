@use './palette';
@use 'colors';
@use 'texts';
@use 'breakpoints';
@use '../ctas/CtasVariables.scss';
@import 'mixins';

.commsBanner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4px;
	padding: 16px;
	color: colors.$content-accent-on-accent;

	a {
		color: colors.$content-accent-on-accent;
		vertical-align: text-top;
	}

	@media screen and (min-width: breakpoints.$large) {
		gap: 16px;
		padding: 16px 48px;
	}
}

.commsBannerTitles {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0;
	align-items: center;
	text-align: center;
	margin: 0;

	div {
		margin-inline: 4px;
	}
}

.commsBannerTitles::after {
	content: ' ';
	background-color: colors.$content-accent-on-accent;
	width: 1px;
	height: 12px;
	margin-top: 2px;
	margin-inline-start: 4px;

	@media screen and (max-width: breakpoints.$large) {
		display: none;
	}
}

.commsBannerCtas {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	margin-bottom: 4px;
	align-items: center;

	@media screen and (max-width: breakpoints.$large) {
		margin-left: 1rem;
	}

	@media screen and (max-width: breakpoints.$medium) {
		margin-left: 0;
	}

	@media screen and (min-width: breakpoints.$medium) {
		margin-right: 2.5rem;
	}
}

.commsBannerTooltip {
	position: absolute;
	right: 1rem;
	bottom: 0.75rem;

	@media screen and (min-width: breakpoints.$large) {
		position: relative;
		right: 2rem;
		top: 2px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.sectionCTAWrapper {
	display: grid;
	justify-items: center;
	grid-template-columns: repeat(2, auto);
	gap: 20px;
	row-gap: 4px;

	@media screen and (min-width: CtasVariables.$two-column-links) {
		grid-template-columns: repeat(4, auto);
		gap: 20px;
	}

	@media screen and (max-width: CtasVariables.$one-column-links) {
		grid-template-columns: 1fr;
	}
}
