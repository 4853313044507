@use 'colors';
@use 'outline';
@use '../../styles/base';
@import '../../styles/states';

$border: 1px solid;

.disabled,
.inverse,
.default {
	@extend %button;

	background: transparent;
	cursor: not-allowed;
}

.default {
	@include states(
		'border',
		$border colors.$border-accent,
		$border colors.$border-accent-hover,
		$border colors.$border-accent-active
	);
	@include states('color', colors.$border-accent, colors.$border-accent-hover, colors.$border-accent-active);
}

.inverse {
	@include states(
		'border',
		$border colors.$border-accent-on-inverse,
		$border colors.$border-accent-on-inverse-hover,
		$border colors.$border-accent-on-inverse-active
	);
	@include states(
		'color',
		colors.$border-accent-on-inverse,
		colors.$border-accent-on-inverse-hover,
		colors.$border-accent-on-inverse-active
	);

	&:focus-visible {
		@extend %inverse-outline-with-offset;
	}
}

[aria-disabled='true'] {
	&.default,
	&.inverse {
		@include states(
			'border',
			$border colors.$border-disabled,
			$border colors.$border-disabled,
			$border colors.$border-disabled
		);
		@include states('color', colors.$border-disabled, colors.$border-disabled, colors.$border-disabled);

		color: colors.$content-disabled;
	}
}
