$width: 9.75rem;
$gap: 1rem;
$max-ctas: $width * 5 + $gap * 6;
$min-ctas: $width * 2 + $gap;
$padding: 3rem;
$padding-h: 2rem;
$padding-h-small: 1rem;
$padding-center: $padding * 2;
$max-width: $max-ctas + $padding-center;
$two-columns: $min-ctas + $padding-center;
$one-column-links: 18.75rem;
$two-column-links: 30rem;
