@use 'colors';
@import 'mixins';

.boundary {
	position: absolute;
	height: 48px;
	inset-block-end: 0;
	inset-inline-end: 0;
}

.infoIconButton {
	position: absolute;
	inset-inline-end: 0;
	inset-block-end: 0;
	height: 48px;
	width: 48px;
	background: none;
	border: none;
	padding: 0;
	color: colors.$content-default;
	cursor: pointer;

	&.inverse {
		color: colors.$content-default-on-inverse;
	}

	svg {
		fill: currentcolor;
	}
}
