@use 'texts';
@import 'mixins';

.lineBanner {
	display: flex;
	flex-direction: column;
	text-decoration-line: none;
	gap: 16px;
	flex: 1;
	min-width: px2rem(226);
}

.lineBannerTitle {
	@extend %text-promo-title-s;

	margin: 0;
	overflow-wrap: break-word;
	hyphens: auto;
}

.imageContainer {
	position: relative;
	width: 100%;
	aspect-ratio: 7 / 5;

	img {
		object-fit: cover;
	}
}
