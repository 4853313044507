@use 'texts';

.bannerTitles {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0;
	text-shadow: 0 0 5px rgba(0 0 0 / 30%);

	.title {
		@extend %text-inspirational-title-l;

		margin: 0;
		text-align: center;
	}

	.subTitle {
		@extend %text-title-m;
	}
}
