@use 'breakpoints';
@use 'texts';
@use 'colors';

$small-inline-padding: 16px;
$large-inline-padding: 32px;

.root {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: $small-inline-padding;
	order: 1;

	@media (min-width: breakpoints.$large) {
		padding-inline: $large-inline-padding;
		padding-block: 40px;
	}
}

.title {
	@extend %text-uppercase-l;

	padding-block-end: 8px;
	margin: 0;

	@media (min-width: breakpoints.$large) {
		padding-block-end: 5px;
	}
}

.scrollWrapper {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.link {
	display: flex;
	align-items: center;
	white-space: nowrap;
	height: 100%;
	padding-inline: 10px;

	@media (min-width: breakpoints.$large) {
		padding-inline: 12px;
	}
}

.linkList {
	height: 48px;
	display: flex;
	flex-wrap: nowrap;
	list-style: none;
	overflow: auto hidden;
	scroll-snap-type: x mandatory;
	width: 100%;
	padding: 0;
	margin-block: 0;
	gap: 0;

	&::-webkit-scrollbar {
		display: none;
	}

	li:first-child .link {
		padding-inline-start: 0;
	}

	li:last-child .link {
		padding-inline-end: 0;
	}
}

.listEl {
	scroll-snap-align: start;
}

%button {
	position: absolute;
	display: flex;
	height: 48px;
	width: 48px;
	inset-block-start: 50%;
	transform: translateY(-50%);
	align-items: center;
	justify-content: center;
	background-color: colors.$content-accent-on-accent;
}

.buttonPrevious {
	@extend %button;

	inset-inline-start: -$small-inline-padding;

	@media (min-width: breakpoints.$large) {
		inset-inline-start: -12px;
	}
}

.buttonPrevious::after {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: -50%;
	width: 24px;
	height: 100%;
	background: linear-gradient(
		to right,
		rgba(colors.$background-default, 1) 0%,
		rgba(colors.$background-default, 0) 100%
	);
	pointer-events: none;
}

.buttonNext {
	@extend %button;

	inset-inline-end: -$small-inline-padding;

	@media (min-width: breakpoints.$large) {
		inset-inline-end: -12px;
	}
}

.buttonNext::before {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: -50%;
	width: 24px;
	height: 100%;
	background: linear-gradient(
		to left,
		rgba(colors.$background-default, 1) 0%,
		rgba(colors.$background-default, 0) 100%
	);
	pointer-events: none;
}

.buttonIcon {
	color: colors.$content-default;
}
