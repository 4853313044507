@use 'colors';
@import 'responsive/utils/mediaQueries.module';

.bannerWrapper {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	height: 375px;

	@include onLargeOrGreater {
		height: 440px;
	}
}

.bannerContainer {
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	inset: 0;
	padding: 40px 16px;
	color: colors.$content-accent-on-inverse;

	@include onMedium {
		padding: 48px;
	}

	@include onLargeOrGreater {
		padding: 80px;
	}
}

.ctas {
	display: flex;
	gap: 8px;
}
