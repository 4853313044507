@use 'breakpoints';
@import 'responsive/utils/mediaQueries.module';

.root {
	display: flex;
	flex-direction: column;
	padding-block: 24px;
	padding-inline: 16px;
	gap: 24px;

	@include onMedium {
		padding-block: 32px;
	}

	@include onLargeOrGreater {
		flex-direction: row;
		padding-block: 48px;
		padding-inline: 32px;
		gap: 48px;
	}
}

.imageContainer {
	display: flex;
	justify-content: flex-start;
	min-height: 263px;

	@include onLargeOrGreater {
		justify-content: center;
		align-items: center;
		flex-basis: 50%;
		min-height: 468px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@include onLargeOrGreater {
		flex-basis: 50%;
	}
}

.title {
	display: flex;
	flex-direction: column;
	margin-block-end: 16px;

	@include onLargeOrGreater {
		margin-block-end: 24px;
	}
}

.italicTitle {
	font-style: italic;
}

.cta {
	margin-block-start: 24px;
	text-decoration: none;

	@include onLargeOrGreater {
		margin-block-start: 40px;
	}
}
