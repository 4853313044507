@use 'colors';
@use 'texts';
@import 'responsive/utils/mediaQueries.module';

$button-height: 44px;

.bannerWrapper {
	display: flex;
	position: relative;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	aspect-ratio: 5 / 7;

	@include onMedium {
		height: 600px;
	}

	@include onLargeOrGreater {
		height: 75vh;
	}
}

.marquee {
	height: calc(100% - $button-height - 40px);
	color: colors.$content-accent-on-inverse;
	text-shadow: 0 0 5px rgba(0 0 0 / 30%);

	@include onMedium {
		height: calc(100% - $button-height - 48px);
	}

	@include onLargeOrGreater {
		height: calc(100% - $button-height - 80px);
	}
}

.bannerTitles {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.title {
	@extend %text-inspirational-body-l;

	margin: 0;
}

.boldTitle {
	@extend %text-inspirational-title-xl;
}

.ctas {
	display: flex;
	position: absolute;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	inset-block-end: 40px;
	padding-inline: 40px;
	gap: 8px;

	@include onMedium {
		inset-block-end: 48px;
		padding-inline: 48px;
	}

	@include onLargeOrGreater {
		inset-block-end: 80px;
	}
}

.pauseButton {
	position: absolute;
	inset-inline-end: 16px;
	bottom: 16px;

	@include onLargeOrGreater {
		inset-inline-end: 24px;
		bottom: 24px;
	}
}
