@use 'colors';
@import 'responsive/utils/mediaQueries.module';

.bannerWrapper {
	display: flex;
	position: relative;
	justify-items: center;
	aspect-ratio: 5 / 7;
	width: 100%;
	line-height: 0;

	@include onMedium {
		height: 600px;
	}

	@include onLargeOrGreater {
		height: 75vh;
	}
}

.layout {
	display: grid;
	grid-template-areas: 'all';
	grid-template-columns: 1fr;
	width: 100%;
	position: absolute;
	height: 100%;

	@include onLargeOrGreater {
		grid-template-areas: 'left right';
		grid-template-columns: calc(75vh * 5 / 7) 1fr;
	}
}

.image {
	grid-area: all;

	@include onLargeOrGreater {
		grid-area: left;
	}
}

.solidColor {
	grid-area: none;
	display: none;

	@include onLargeOrGreater {
		grid-area: right;
		display: initial;
	}
}

.promoGeneral {
	padding: 40px 16px;
	width: 100%;
	height: 100%;
	display: flex;
	gap: 16px;
	flex-direction: column;
	position: relative;
	grid-area: all;

	@include onMedium {
		padding: 48px;
	}

	@include onLargeOrGreater {
		grid-area: right;
		padding: 80px;
	}
}

.promo {
	height: 100%;
	display: flex;
	flex-direction: column;
	color: colors.$content-accent-on-inverse;
}

.counter,
.subtitles {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-shadow: 0 0 5px rgba(0 0 0 / 30%);
	text-align: center;

	@include onLargeOrGreater {
		text-shadow: none;
	}
}

.subtitles {
	margin: 0;
}

.ctas {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.infoTooltip {
	width: calc(100% - 80px);
}
