@use 'colors';

.black {
	background-color: colors.$promotions-promotion-bf;
}

.red {
	background-color: colors.$promotions-promotion-sales;
}

.lightBlue {
	background-color: colors.$promotions-promotion2;
}

.darkBlue {
	background-color: colors.$promotions-promotion3;
}

.lightGreen {
	background-color: colors.$promotions-promotion4;
}

.darkGreen {
	background-color: colors.$promotions-promotion5;
}

.orange {
	background-color: colors.$promotions-promotion6;
}

.lightBurgundy {
	background-color: colors.$promotions-promotion7;
}

.darkBurgundy {
	background-color: colors.$promotions-promotion8;
}
