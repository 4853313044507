@use 'colors';

.heroBannerFullHeightWrapper {
	display: flex;
	position: relative;
	justify-items: center;
	justify-content: center;
	width: 100%;
	line-height: 0;
	background-color: colors.$background-default;
	overflow: hidden;
}
