@use 'breakpoints';

.bannerWrapper {
	display: flex;
	position: relative;
	justify-items: center;
	aspect-ratio: 5 / 7;
	width: 100%;
	line-height: 0;

	@media (min-width: breakpoints.$medium) and (max-width: breakpoints.$large) {
		height: 600px;
	}

	@media (min-width: breakpoints.$large) {
		height: 75vh;
	}
}
