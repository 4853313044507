@use 'breakpoints';

$column-row-gap: 1px;
$one-column-margin: 16px;
$two-column-margin: 16px;
$three-column-margin: 32px;
$vertical-gap-small: 24px;
$vertical-gap-large: 48px;

%list-reset {
	list-style: none;
	padding-inline-start: 0;
	margin-block: 0;
	margin-inline: 0;
}

.familiesBanner {
	@extend %list-reset;

	display: grid;
	gap: $vertical-gap-small $column-row-gap;
	grid-template-columns: 1fr;
	margin: $one-column-margin;
	flex-wrap: wrap;

	@media (min-width: breakpoints.$medium) {
		&.minFamiliesBanner {
			grid-template-columns: repeat(3, 1fr);
		}

		&.maxFamiliesBanner {
			grid-template-columns: repeat(2, 1fr);
		}

		margin: $two-column-margin;
	}

	@media (min-width: breakpoints.$large) {
		&.minFamiliesBanner,
		&.maxFamiliesBanner {
			grid-template-columns: repeat(3, 1fr);
		}

		gap: $vertical-gap-large $column-row-gap;
		margin: $three-column-margin;
	}
}
