@use 'texts';
@use 'breakpoints';
@import 'mixins';

.heroBannerTextCenterWrapper {
	position: absolute;
	top: 50%;
	padding: 0 px2rem(16);
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: px2rem(12);
	max-width: 100%;

	&.titleImageContainer {
		width: 100%;
		height: calc(100% - 160px);
	}

	@media (min-width: breakpoints.$large) {
		padding: 0 px2rem(32);
	}
}
