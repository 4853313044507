@use 'breakpoints';
@use 'colors';
@use 'motion';
@import 'mixins';

.carouselType {
	&Variant1,
	&Variant2,
	&Variant3,
	&Variant4 {
		--container-margin-inline: 16px;

		@media (min-width: breakpoints.$large) {
			--container-margin-inline: 32px;
		}
	}

	&Variant4 {
		--container-margin-inline: 0px;
	}
}

.header {
	opacity: 0;
	display: flex;
	justify-content: space-between;
	margin-inline: var(--container-margin-inline);
	align-items: flex-end;
	animation: motion-opacity motion.$duration-large3 motion.$easing-calm 0s 1 forwards;

	&.withoutContent {
		justify-content: flex-end;
	}

	@media (prefers-reduced-motion: reduce) {
		opacity: 1;
		animation: none;
	}
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	gap: px2rem(4);
}

.title,
.subtitle,
.status {
	margin: 0;
}

.actions {
	display: flex;
	gap: px2rem(16);
	align-items: center;
}

.status {
	color: colors.$semantic-warning;
}

@keyframes motion-opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
