@use 'colors';
@use 'breakpoints';
@import 'mixins';

.videoContainer {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.videoButton {
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	padding: 0;
	min-width: 0;
	width: px2rem(48);
	height: px2rem(48);
	color: colors.$content-accent;
	border-color: colors.$content-accent;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	svg {
		width: 24px;
		height: 24px;
		fill: currentcolor;
	}

	path {
		fill: currentcolor;
	}

	&:hover {
		color: colors.$border-accent-hover;
		border-color: colors.$border-accent-hover;
	}

	&.inverse {
		color: colors.$content-accent-on-inverse;
		border-color: colors.$content-accent-on-inverse;

		&:hover {
			color: colors.$content-accent-on-inverse-hover;
			border-color: colors.$content-accent-on-inverse-hover;
		}
	}

	@media (min-width: breakpoints.$large) {
		inset-inline-end: 1.5rem;
		bottom: 1.5rem;
	}
}

.hidden {
	display: none;
}
