@use 'colors';

.singleCta {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	min-width: 90px;
	width: fit-content;
	padding: 0 32px;
	color: colors.$content-accent;
	background-color: colors.$background-accent-on-inverse;

	&:hover {
		background-color: colors.$background-accent-on-inverse-hover;
	}
}

.active {
	opacity: 0.5;
	background-color: colors.$background-accent-on-inverse-active;
}
