@use 'colors';
@import 'mixins';

.arrows {
	display: flex;
	align-items: center;
}

.arrow {
	width: px2rem(24);
	height: px2rem(24);
	z-index: 1;
	background-color: colors.$background-accent-on-image-hover;
	min-width: unset;
	min-height: unset;
	padding: 0;
}
