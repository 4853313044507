@use 'colors';
@use 'texts';
@use 'breakpoints';
@use 'motion';
@use 'mixins';

.familyContentBannerWrapper {
	height: 100%;
	width: 100%;
	position: relative;
}

.textContainer {
	position: absolute;
	margin: 0;
	width: 100%;
	display: flex;
	inset-block-start: 0;
	padding: 16px;
	opacity: var(--text-opacity);

	@media screen and (min-width: breakpoints.$medium) {
		padding: 24px;
	}

	@media screen and (min-width: breakpoints.$large) {
		padding: 32px;
	}
}

.inverse {
	color: colors.$content-accent-on-inverse;
	background: linear-gradient(to top, rgba(19 19 19 / 20%) 0%, rgba(19 19 19 / 0%) 100%);
}

.fixedText {
	.textContainer {
		position: fixed;
		inset-block-start: inherit;
		inset-block-end: 0;
		width: 50%;
	}
}

.normalTextBottom {
	.textContainer {
		position: absolute;
		inset-block-start: inherit;
		inset-block-end: 0;
	}
}

.textWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}

.familyBannerTitle {
	margin: 0;
	text-transform: uppercase;

	@extend %text-uppercase-m;
}

.cta {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@extend %text-uppercase-m;

	&:hover {
		text-decoration: underline;
		text-decoration-thickness: px2rem(1px);
		text-underline-offset: 8px;
	}
}

.textPlaceholder {
	position: absolute;
	width: 100%;
	height: var(--div-height);
	inset-block-start: 0;
}

.endBanner {
	position: absolute;
	inset-block-end: 0;
}
