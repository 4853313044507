@use 'colors';
@use 'breakpoints';

.banner {
	position: relative;
	border-bottom: 1px solid colors.$border-subtle;
	background-color: colors.$background-default;
	width: 100%;

	.content {
		padding: 16px;
		width: calc(100% - 32px);
	}

	@media (min-width: breakpoints.$medium) {
		position: fixed;
		right: 40px;
		z-index: 1; // above main stacking context
		margin-top: 20px;
		border: 1px solid colors.$border-subtle;
		width: 298px;

		.content {
			padding: 24px 8px 16px 24px;
		}
	}

	.buttonClose {
		position: absolute;
		top: 4px;
		right: 8px;
		cursor: pointer;
		border: 0;
		background: none;
		padding: 0;
		width: 24px;
		height: 24px;
		color: colors.$content-default;
		font-size: 24px;

		@media (min-width: breakpoints.$medium) {
			top: 8px;
		}
	}
}
