@use 'breakpoints';
@import 'mixins';

.titles {
	display: flex;
	flex-flow: column nowrap;
	gap: 4px;
	align-items: center;
	text-align: center;
	margin: 0;
	width: fit-content;

	@media screen and (min-width: breakpoints.$large) {
		flex-flow: row wrap;
		justify-content: center;
		gap: 4px 8px;
	}
}

.title {
	margin: 0;
	display: flex;
	gap: 8px;

	@media screen and (min-width: breakpoints.$large) {
		gap: 4px;
	}
}

.subtitlesGroup {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4px;
}

.subtitle {
	margin: 0;
}
