@import 'responsive/utils/mediaQueries.module';

$column-row-gap-large: 1px;
$column-row-gap-small: 8px;
$one-column-margin: 16px;
$two-column-margin: 32px;
$vertical-gap-small: 32px;
$vertical-gap-large: 48px;

%list-reset {
	list-style: none;
	padding-inline-start: 0;
	margin-block: 0;
	margin-inline: 0;
}

.linesBanner {
	@extend %list-reset;

	display: grid;
	flex-wrap: wrap;
	grid-template-columns: 1fr;
	gap: $vertical-gap-small $column-row-gap-small;
	margin: $one-column-margin $one-column-margin $two-column-margin $one-column-margin;

	@include onMediumOrGreater {
		grid-template-columns: repeat(2, 1fr);
	}

	@include onLargeOrGreater {
		gap: $vertical-gap-large $column-row-gap-large;
		margin: $two-column-margin;
	}
}
