@use 'breakpoints';
@import 'mixins';

.carousel {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;

	@media (min-width: breakpoints.$large) {
		margin-top: 40px;
	}
}
