@use 'colors';
@use 'breakpoints';
@import 'mixins';

$mobile-ctas-gap: 8;

.videoButton {
	width: px2rem(36);
	height: px2rem(36);
	bottom: px2rem(16);
	left: px2rem(16);
	min-width: 0;
	padding: 0;

	@media (min-width: breakpoints.$large) {
		left: unset;
		right: px2rem(48);
		bottom: px2rem(48);
	}

	@media (min-width: breakpoints.$extra-large) {
		right: px2rem(91);
	}
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	z-index: 1;
	left: 50%;
	bottom: px2rem(67);
	transform: translateX(-50%);
	width: calc(100% - px2rem(32));
	gap: px2rem(28);

	@media (min-width: breakpoints.$medium) {
		bottom: px2rem(32);
		width: calc(100% - px2rem(136));
	}

	@media (min-width: breakpoints.$large) {
		gap: px2rem(32);
		bottom: px2rem(48);
		width: calc(100% - px2rem(200));
	}

	@media (min-width: breakpoints.$extra-large) {
		width: calc(100% - px2rem(286));
	}
}

.ctaWrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: px2rem($mobile-ctas-gap);

	@media (min-width: breakpoints.$medium) {
		width: fit-content;
		gap: px2rem(12);
	}
}

.buttonCta {
	width: calc(50% - px2rem($mobile-ctas-gap / 2));

	@media (min-width: breakpoints.$medium) {
		width: fit-content;
	}
}
