@use 'colors';
@use 'breakpoints';
@import 'mixins';

.heroBannerMultibrandTextCenterWrapper {
	position: absolute;
	top: 50%;
	padding: 0 px2rem(16);
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: px2rem(12);
	max-width: 100%;

	@media (min-width: breakpoints.$large) {
		padding: 0 px2rem(32);
	}
}

.hiddenImage {
	display: none;
}

.ctasWrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.ctaButton {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.ctaLink {
	display: flex;
	align-items: center;
	height: px2rem(48);
	padding: 0 px2rem(12);

	@media (min-width: breakpoints.$large) {
		padding: 0 px2rem(20);
	}
}

.ctaText {
	display: flex;
	align-items: center;
	height: px2rem(48);
	color: colors.$content-accent;

	&:hover {
		text-decoration: underline;
		text-decoration-thickness: px2rem(1);
		text-underline-offset: px2rem(8);
	}

	&.inverse {
		color: colors.$content-accent-on-inverse;
	}
}
