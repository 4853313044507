@use 'motion';

.opacityFadeIn {
	opacity: 0;
	animation: fade-in motion.$duration-large2 motion.$easing-calm motion.$delay-large1 forwards;

	@keyframes fade-in {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}
