@use 'breakpoints';
@use 'motion';
@import 'mixins';

.carouselType {
	&Variant1,
	&Variant2,
	&Variant3,
	&Variant4 {
		--gap: 1px;
		--visible-partial: 16px;
		--container-margin-inline: 16px;

		@media (min-width: breakpoints.$large) {
			--visible-partial: 32px;
			--container-margin-inline: 32px;
		}
	}

	&Variant1 {
		--visible-items: 1;

		@media (min-width: breakpoints.$medium) {
			--visible-items: 2;
		}

		@media (min-width: breakpoints.$large) {
			--visible-items: 4;
		}

		@media (min-width: breakpoints.$extra-large) {
			--visible-items: 5;
		}
	}

	&Variant2 {
		--visible-items: 2;

		@media (min-width: breakpoints.$medium) {
			--visible-items: 3;
		}

		@media (min-width: breakpoints.$large) {
			--visible-items: 5;
		}

		@media (min-width: breakpoints.$extra-large) {
			--visible-items: 6;
		}
	}

	&Variant3 {
		--visible-items: 2;

		@media (min-width: breakpoints.$medium) {
			--visible-items: 4;
		}

		@media (min-width: breakpoints.$large) {
			--visible-items: 6;
		}

		@media (min-width: breakpoints.$extra-large) {
			--visible-items: 7;
		}
	}

	&Variant4 {
		--visible-items: 2;
		--visible-partial: 16px;
		--container-margin-inline: 0px;
	}
}

.items {
	position: relative;
	opacity: 0;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: calc(
		(100% - (var(--gap) * var(--visible-items)) - var(--visible-partial) + var(--container-margin-inline)) /
			var(--visible-items)
	);
	gap: var(--gap);
	padding: 0;
	overflow-x: auto;
	list-style-type: none;
	margin: 0;
	padding-inline: var(--container-margin-inline);
	scroll-snap-type: x mandatory;
	scroll-padding-inline: var(--container-margin-inline);
	scroll-behavior: smooth;
	scrollbar-width: none;
	-ms-overflow-style: none;
	animation: motion-opacity motion.$duration-extra-large2 motion.$easing-calm motion.$delay-medium1 1 forwards;

	@media (prefers-reduced-motion: reduce) {
		opacity: 1;
		animation: none;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.item {
	user-select: none;
	-webkit-user-drag: none;
	scroll-snap-align: start;

	& a,
	img {
		-webkit-user-drag: none;
	}
}

@keyframes motion-opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
