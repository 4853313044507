@use 'breakpoints';
@use 'mixins';

$image-aspect-ratio-small: 5 / 7;
$image-aspect-ratio-medium: 5 / 7;
$image-aspect-ratio-large: 1;

.root {
	width: 100%;
	display: flex;

	> div {
		width: 50%;
	}
}

.familyBannerWrapper {
	position: relative;
	width: 100%;
	aspect-ratio: $image-aspect-ratio-small;

	@media screen and (min-width: breakpoints.$medium) {
		aspect-ratio: $image-aspect-ratio-medium;
	}

	@media screen and (min-width: breakpoints.$large) {
		aspect-ratio: $image-aspect-ratio-large;
	}
}
