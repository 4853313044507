@use 'colors';
@use 'breakpoints';
@import 'mixins';

.infoIconButton {
	position: absolute;
	display: flex;
	height: 48px;
	padding-top: 12px;
	padding-left: 12px;
	inset-block-end: 0;
	align-items: center;
	justify-content: center;
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;

	svg {
		fill: currentcolor;
	}

	@media screen and (min-width: breakpoints.$large) {
		padding: 0;
		inset-block-start: 50%;
		transform: translateY(-50%);
	}
}

.infoIconButtonSingleComm {
	width: 48px;
	inset-inline-end: 0;

	@media screen and (min-width: breakpoints.$large) {
		inset-inline-end: 48px;
	}
}

.closableWrapper {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	color: colors.$content-default;

	@media screen and (min-width: breakpoints.$large) {
		flex-flow: row wrap;
		justify-content: center;
		gap: 0 24px;
	}

	&.inverse {
		color: colors.$content-default-on-inverse;
	}
}

.closeButton {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	height: 48px;
	width: 48px;
	padding: 12px 12px 0 0;
	min-width: 0;

	span {
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			fill: currentcolor;
		}
	}

	@media screen and (min-width: breakpoints.$large) {
		inset-inline-end: 48px;
		padding: 0;
	}
}

.white {
	border-color: colors.$border-subtle;
	background-color: colors.$background-default;
}

.black {
	background-color: colors.$semantic-info;
}

.red {
	background-color: colors.$promotions-promotion-sales;
}

.lightBlue {
	background-color: colors.$promotions-promotion2;
}

.darkBlue {
	background-color: colors.$promotions-promotion3;
}

.lightGreen {
	background-color: colors.$promotions-promotion4;
}

.darkGreen {
	background-color: colors.$promotions-promotion5;
}

.orange {
	background-color: colors.$promotions-promotion6;
}

.lightBurgundy {
	background-color: colors.$promotions-promotion7;
}

.darkBurgundy {
	background-color: colors.$promotions-promotion8;
}
