@use 'colors';

.link {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin: 8px 0 0 8px;
	border: none;
	background: transparent;
	padding: 0;
	width: fit-content;
	color: colors.$content-default;
	text-decoration: none;
}

.withIcon {
	margin-inline-start: 32px;
}

.icon {
	margin-inline-start: 4px;
}
