@use './breakpoints';
@use './colors';

.textContainer {
	display: flex;
	flex-direction: column;

	&.low {
		flex: 1;
	}

	&.promoColor {
		color: colors.$promotions-promotion-sales;
	}

	&.customColor {
		color: var(--custom-content-color);
	}
}

.subtitleTop {
	margin-block-end: 8px;
}

.promoTitle {
	margin-block-end: 4px;
}

.discount {
	margin-block-end: 12px;
}

.subtitlesGroup {
	display: flex;
	flex-direction: column;
	gap: 4px;
	list-style-type: none;
	padding-inline-start: 0;
	margin-block: 0 8px;
}

.subtitlesGroupItem {
	display: flex;
	gap: 6px;
}

.subtitlesGroupTitle {
	@media (min-width: breakpoints.$large) {
		font-weight: 500;
		font-size: px2rem(16);
		line-height: px2rem(18);
		font-family: var(--mango-primary-font);
		text-transform: uppercase;
	}
}
