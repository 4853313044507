@use './CtasVariables.scss';
@import 'responsive/utils/mediaQueries.module';

.ctasCenterWrapper {
	display: flex;
	position: relative;
	bottom: 0;
	justify-content: center;
	width: 100%;

	&.absolute {
		position: absolute;
	}
}

.ctasWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: CtasVariables.$gap;
	padding: CtasVariables.$padding CtasVariables.$padding-h-small;
	width: CtasVariables.$two-columns;

	@include onLargeOrGreater {
		padding: CtasVariables.$padding CtasVariables.$padding-h;
	}

	@media (min-width: CtasVariables.$max-width) {
		width: CtasVariables.$max-width;
	}

	div {
		width: CtasVariables.$width;
	}
}
