@use 'colors';
@use 'breakpoints';
@import 'mixins';

.carouselWrapper {
	position: relative;
	display: flex;
	min-height: 48px;
}

.scrollButton {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	color: colors.$content-default;
	width: 48px;
	height: 48px;

	@media screen and (min-width: breakpoints.$large) {
		width: 32px;
	}

	svg {
		fill: currentcolor;
	}

	&.inverse {
		color: colors.$content-default-on-inverse;
	}

	&.fitHeight {
		top: 24px;
		transform: translateY(0);

		@media screen and (min-width: breakpoints.$large) {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&:hover {
		background-color: transparent;
	}
}

.prevScrollButton {
	left: 0;

	@media screen and (min-width: breakpoints.$large) {
		left: 48px;
	}
}

.nextScrollButton {
	right: 0;

	@media screen and (min-width: breakpoints.$large) {
		right: 48px;
	}
}

.bannerList {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
	gap: 0;

	@media screen and (min-width: breakpoints.$large) {
		gap: 0;
		overflow: hidden;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.bannerListItem {
	position: relative;
	width: 100%;
	flex: 0 0 100%;
}

.infoIconButtonCarousel {
	width: 48px;
	inset-inline-end: 0;

	@media screen and (min-width: breakpoints.$large) {
		width: 32px;
		inset-inline-end: 80px;
	}
}
