@use 'breakpoints';
@import 'responsive/utils/mediaQueries.module';

.crossSellingCarousel {
	margin: 0;
	padding-top: 16px;
	padding-bottom: 16px;

	@include onMediumOrGreater {
		padding-top: 40px;
		padding-bottom: 32px;
	}
}
