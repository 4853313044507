@use 'breakpoints';

.marquee {
	display: flex;
	position: absolute;
	inset: 0;
	overflow: hidden;
}

.marqueeGroup {
	display: flex;
	flex-shrink: 0;
	justify-content: space-around;
	align-items: center;
	min-width: 100%;
	gap: 1rem;
	padding-right: 1rem;

	&.animated {
		animation: marquee-animation 60s linear infinite;

		@media (prefers-reduced-motion: reduce) {
			animation-play-state: paused;
		}
	}

	&.hidden {
		visibility: hidden;
	}
}

.pausedMarqueeGroup {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	min-width: 100%;
	gap: 1rem;
	transform: translateX(40%);

	@media (min-width: breakpoints.$medium) {
		justify-content: center;
		transform: translateX(0);
	}
}

@keyframes marquee-animation {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}
