@use 'breakpoints';

.titles {
	height: 100%;
}

.titlesWrapper {
	display: flex;
	flex-flow: column wrap;
	height: 100%;
	justify-content: center;
	text-align: center;
	font-size: inherit;
	font-weight: inherit;
	margin: 0;
	text-shadow: 0 0 5px rgba(0 0 0 / 30%);

	@media (min-width: breakpoints.$medium) {
		gap: 12px;
	}

	@media (min-width: breakpoints.$large) {
		text-shadow: none;
	}
}
