@use 'texts';
@use 'breakpoints';
@import 'mixins';

.familyBanner {
	display: flex;
	flex-direction: column;
	text-decoration-line: none;
	gap: 16px;
	flex: 1;
	min-width: px2rem(226);

	@media screen and (min-width: breakpoints.$medium) {
		min-width: px2rem(315);
	}

	@media screen and (min-width: breakpoints.$large) {
		min-width: px2rem(226);
	}
}

.familyBannerTitle {
	margin: 0;
	overflow-wrap: break-word;
	hyphens: auto;

	@extend %text-inspirational-title-s;
}

.textContainer {
	margin: 0;
}

.imageContainer {
	position: relative;
	width: 100%;
	aspect-ratio: 5 / 7;

	img {
		object-fit: cover;
	}
}
