@import 'responsive/utils/mediaQueries.module';

$padding-y-carousel-s-m: calc(24px * 2);
$padding-y-carousel-l: calc(32px * 2);
$header-height: 24px;
$header-gap: 16px;
$scroll-bar-width: 15px;
$total-header: calc(#{$header-height} + #{$header-gap});
$product-card-action-s-m: 44px;
$product-card-action-l: 16px;
$desc-height-product-s: calc(44px + #{$product-card-action-s-m});
$desc-height-product-m: calc(56px + #{$product-card-action-s-m});
$desc-height-product-l: calc(54px + #{$product-card-action-l});
$s: calc(#{$padding-y-carousel-s-m} + #{$total-header} + #{$desc-height-product-s});
$m: calc(#{$padding-y-carousel-s-m} + #{$total-header} + #{$desc-height-product-m});
$l: calc(#{$padding-y-carousel-l} + #{$total-header} + #{$desc-height-product-l});
$image-x-padding-s-m: calc(16px * 2);
$image-x-padding-l: calc(32px * 2);
$image-width-1-image: calc(100vw - 1px - #{$scroll-bar-width} - #{$image-x-padding-s-m});
$image-width-2-images: calc((100vw - 2px - #{$scroll-bar-width} - #{$image-x-padding-s-m}) / 2);
$image-width-4-images: calc((100vw - 4px - #{$scroll-bar-width} - #{$image-x-padding-l}) / 4);
$image-width-5-images: calc((100vw - 5px - #{$scroll-bar-width} - #{$image-x-padding-l}) / 5);
$image-width-6-images: calc((100vw - 6px - #{$scroll-bar-width} - #{$image-x-padding-l}) / 6);
$image-width-7-images: calc((100vw - 7px - #{$scroll-bar-width} - #{$image-x-padding-l}) / 7);
$image-height-s-var-1: calc(#{$image-width-1-image} * 7 / 5);
$image-height-m-var-1: calc(#{$image-width-2-images} * 7 / 5);
$image-height-l-var-1: calc(#{$image-width-4-images} * 7 / 5);
$image-height-xl-var-1: calc(#{$image-width-5-images} * 7 / 5);
$image-height-s-var-3: calc(#{$image-width-2-images} * 7 / 5);
$image-height-m-var-3: calc(#{$image-width-4-images} * 7 / 5);
$image-height-l-var-3: calc(#{$image-width-6-images} * 7 / 5);
$image-height-xl-var-3: calc(#{$image-width-7-images} * 7 / 5);

.serverPlaceholderVariant1 {
	height: calc(#{$s} + #{$image-height-s-var-1});

	@include onMedium {
		height: calc(#{$m} + #{$image-height-m-var-1});
	}

	@include onLarge {
		height: calc(#{$l} + #{$image-height-l-var-1});
	}

	@include onExtraLarge {
		height: calc(#{$l} + #{$image-height-xl-var-1});
	}
}

.serverPlaceholderVariant3 {
	height: calc(#{$s} + #{$image-height-s-var-3});

	@include onMedium {
		height: calc(#{$m} + #{$image-height-m-var-3});
	}

	@include onLarge {
		height: calc(#{$l} + #{$image-height-l-var-3});
	}

	@include onExtraLarge {
		height: calc(#{$l} + #{$image-height-xl-var-3});
	}
}
