@use 'colors';
@use 'breakpoints';

.ctasWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	gap: 8px;

	@media (min-width: breakpoints.$medium) {
		display: flex;
		flex-wrap: wrap;
	}

	&.low {
		flex: 1;

		&.singleCta {
			flex: 0;
		}
	}
}

.ctaButton {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 32px;
	text-decoration: none;
	color: colors.$content-accent-on-accent;
	background-color: colors.$background-accent;

	@media (min-width: breakpoints.$medium) {
		min-width: 168px;
	}

	@media (min-width: breakpoints.$large) {
		min-width: 0;
	}

	&:hover {
		background-color: colors.$background-accent-hover;
	}

	&.low {
		@media (min-width: breakpoints.$large) {
			min-width: 119px;
		}
	}
}
