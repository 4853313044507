@use 'breakpoints';

.scrollable {
	display: flex;
	column-gap: 16px;
	width: 100%;
	overflow: scroll hidden;

	&.justifyCenter {
		justify-content: center;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: breakpoints.$large) {
		column-gap: 24px;
	}
}
