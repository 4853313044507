@import 'responsive/utils/mediaQueries.module';

.carousel {
	margin: 0;
	padding-inline: 0;
	padding-block: 24px;

	@include onLargeOrGreater {
		padding-block: 32px;
	}
}
