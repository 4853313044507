@use 'breakpoints';
@use 'colors';

.promotionalBannerWrapper {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	gap: 24px;
	padding: 32px 16px;

	@media (min-width: breakpoints.$large) {
		flex-direction: row;
		padding: 32px;
		gap: 48px;
	}

	&.low {
		position: relative;
		padding: 48px 16px;

		@media (min-width: breakpoints.$large) {
			padding: 48px 176px;
		}
	}
}

.imageAndInfoWrapper {
	position: relative;
	width: 100%;
	aspect-ratio: 1 / 1;

	@media (min-width: breakpoints.$medium) {
		aspect-ratio: 7 / 5;
	}

	@media (min-width: breakpoints.$large) {
		width: 50%;
		aspect-ratio: 7 / 5;
	}
}

.textAndCtasWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 24px;

	@media (min-width: breakpoints.$medium) {
		flex: 1;
	}

	&.low {
		@media (min-width: breakpoints.$large) {
			gap: 48px;
			flex-direction: row;
			align-items: center;
		}
	}
}

.infoIcon {
	width: 100%;

	svg {
		color: colors.$content-default;
		width: 12px;
		height: 12px;
	}
}

.imageWrapper {
	height: 100%;
	width: 100%;
}
